.footerMainContainer {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.footerContainer {
  position: absolute;
  width: 100vw;
  border-top: 1px solid #d9d9d9;
}

.footerNavigationContainer {
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 2vh;
}

.footerNavigationMain {
  text-align: left;
  width: 100vw;
  margin-top: 1vh;
}

.footerNavigationMainList {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: 0 1rem;
}

.footerUlNoBullets {
  list-style-type: none;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.footerUlNoBulletsLabel {
  font-weight: 700;
  margin-bottom: 0.25rem;
  font-size: 12px;
}

.footerUlNoBulletsOptions {
  padding-top: 0.25rem;
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
}

.footerNavigationSubscribe {
  margin-right: 0;
  text-align: left;
  margin-left: 5vw;
}

.footerNavigationSubscribeLabel {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 12px;
}

.footerNavigationSubscribeSocialMedia {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.footerNavigationSubscribeSocialMedia img {
  margin: 0 0.5rem 0 0;
  width: 16px;
  height: auto;
}

.footerPaymentAccept {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-right: 5vw;
}

.footerPaymentAcceptLabel {
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-size: 12px;
}

.footerPaymentAcceptIcons {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerPaymentAcceptIcons img {
  margin: 0 0.5rem 0 0;
}

.visaIconStyle {
  width: 60px;
  height: auto;
}

.americanExpressIconStyle {
  width: 25px;
  height: auto;
}

.paypalIconStyle {
  width: 50px;
  height: auto;
}

.applePayIconStyle {
  width: 25px;
  height: auto;
}

@media only screen and (min-width: 640px) {
  .footerMainContainer {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .footerContainer {
    position: absolute;
    width: 100%;
    border-top: 1px solid #d9d9d9;
  }

  .footerNavigationContainer {
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 3vh;
  }

  .footerNavigationMain {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    margin-top: 0;
  }

  .footerNavigationMainList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 5vw;
    width: 45vw;
    gap: 5vw;
  }

  .footerUlNoBullets {
    list-style-type: none;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .footerUlNoBulletsLabel {
    font-weight: 700;
    margin-bottom: 1vh;
    font-size: 0.9vw;
  }

  .footerUlNoBulletsOptions {
    padding-top: 0.5vh;
    font-weight: 500;
    font-size: 0.85vw;
  }

  .footerNavigationSubscribe {
    margin-right: 5vw;
    margin-left: 0;
  }

  .footerNavigationSubscribeLabel {
    font-weight: 700;
    margin-bottom: 1.5vh;
    margin-top: 2vh;
    font-size: 0.95vw;
  }

  .footerNavigationSubscribeSocialMedia {
    display: flex;
    align-items: center;
  }

  .footerNavigationSubscribeSocialMedia img {
    margin: 0 1vw 0 0;
    width: 1.5vw;
    height: auto;
  }

  .footerPaymentAccept {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    margin-top: 2vh;
    margin-bottom: 0;
    margin-right: 0;
  }

  .footerPaymentAcceptLabel {
    font-weight: 700;
    margin-bottom: 1.5vh;
    font-size: 0.95vw;
  }

  .footerPaymentAcceptIcons {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .footerPaymentAcceptIcons img {
    margin: 0 1vw 0 0;
  }

  .visaIconStyle {
    width: 70px;
    height: auto;
  }

  .americanExpressIconStyle {
    width: 30px;
    height: auto;
  }

  .paypalIconStyle {
    width: 60px;
    height: auto;
  }

  .applePayIconStyle {
    width: 30px;
    height: auto;
  }
}
