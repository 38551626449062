.itemDetailContainer {
  margin-top: 8em;
}

.itemDetailContainerContent {
  padding: 1.5em 1.5em 1.5em 1.5em;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.itemDetailImages {
  text-align: left;
  width: 100%;
}

.sliderImageStyle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: auto;
}

.sliderImageStyle img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 10px;
}

.itemDetailDescription {
  text-align: left;
  width: 100%;
}

.itemDetailYouMayAlsoLikeContainer {
  padding: 1.5em 1.5em 1.5em 1.5em;
}

.itemDetailYouMayAlsoLikeContent {
  display: flex;
  gap: 5em;
}

.itemDetailDescriptionBrand {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #006787;
  cursor: pointer;
}

.itemDetailDescriptionItemName {
  margin-top: 0.25em;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.itemDetailDescriptionItemDesc {
  margin-top: 0em;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.variantSelectedContainer {
  color: #006787;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.itemDetailDescriptionPrice {
  margin-top: 0.5em;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0.5em;
}

.itemDetailDescriptionSizeColorQtyContainer {
  margin-top: 1em;
}

.itemDetailDescriptionOptions {
  display: flex;
  align-items: center;
}

.itemDetailDescriptionOptionLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #006787;
}

.itemDetailDescriptionOptionViewSizeGuide {
  margin-left: 1.5em;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #9e9e9e;
  cursor: pointer;
}

.itemDetailDescriptionSizeOptions {
  margin-top: 0.75em;
  display: flex;
  gap: 1em;
}

.eachSizeOptionContainer {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.eachSizeOptionContainer div {
  margin: 0.5em 0.75em 0.5em 0.75em;
}

.eachSizeOptionContainer div {
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vw;
}

.eachColorOptionContainer {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.eachColorOptionContainer div {
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vw;
}

.eachColorOptionContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.itemDetailDescriptionQtyOptionsContainer {
  border: 1px solid #000000;
  border-radius: 5px;
}

.itemDetailDescriptionQtyOptions {
  margin: 0.1em 1em 0.1em 1em;
  display: flex;
  gap: 2.5vw;
  align-items: center;
}

.itemDetailDescriptionQtyValue {
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
}

.itemDetailDescriptionQtyBtn {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}

.itemDetailDescriptionQtyInStockContainer {
  margin-left: 1em;
  display: flex;
  gap: 1em;
  align-items: center;
}

.itemDetailDescriptionQtyInStockBox {
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.itemDetailDescriptionQtyInStockLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.itemDetailDescriptionBtnAddToBag {
  margin-top: 2em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.itemDetailDescriptionBtnAddToBag div {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}

.itemDetailDescriptionBtnAddToWishList {
  margin-top: 0.75em;
  background-color: #e8e8e8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.itemDetailDescriptionBtnAddToWishList img {
  width: 29px;
  height: auto;
  margin-right: 0.75em;
}

.itemDetailDescriptionBtnAddToWishList div {
  margin-top: 0.225em;
  margin-bottom: 0.225em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.itemDetailDescriptionDetails {
  margin-top: 6vh;
}

.eachDetailDescription {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  vertical-align: middle;
}

.eachDetailDescriptionArrowIcon img {
  width: 4.5vw;
  height: auto;
}

.eachDetailDescriptionLabel {
  margin-left: 4.8vw;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;
}

.eachDetailContainer {
  display: flex;
  align-items: center;
  gap: 6.3vw;
  margin-bottom: 20px;
}

.eachDetailListItem {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: -0.5em;
  margin-left: 0.25em;
}

.eachDetailLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: min-content;
  overflow-y: auto;
}

.eachDetailDescriptionOptions {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.itemDetailDescriptionProductCode {
  margin-top: 1.5em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
}

.itemDetailBottomSectionContainer {
  margin-top: 2em;
  background-color: #19b0df;
}

.itemDetailBottomSectionContent {
  padding: 1.5em 1.5em 1.5em 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.offerContainer {
  text-align: left;
}

.offerLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 5vw;
}

.offerDescription {
  margin-top: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 3vw;
  color: #ffffff;
}

.submitEmailContainer {
  margin-top: 1.5em;
  width: 100%;
  text-align: left;
}

.submitEmailLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.submitEmailInputAndSubmitBtnContainer {
  margin-top: 0.25em;
  display: flex;
  gap: 1em;
}

.btnSubmitEmailUser {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnSubmitEmailUser div {
  margin: 0.25em 1em 0.25em 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}

@media only screen and (min-width: 640px) {
  .itemDetailContainer {
    margin-top: 8vh;
  }

  .itemDetailContainerContent {
    padding: 100px 5vw 3vh 5vw;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .itemDetailImages {
    text-align: left;
    width: 60%;
  }

  .sliderImageStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: auto;
  }

  .sliderImageStyle img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    border-radius: 25px;
  }

  .sliderImageStyle img:hover {
    cursor: zoom-in;
  }

  .otherImagesContainer {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .eachOtherImage {
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 47.5%;
    overflow: hidden;
  }

  .eachOtherImage img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 25px;
  }

  .eachOtherImage img:hover {
    cursor: pointer;
    border: #006787 3px solid;
  }

  .itemDetailDescription {
    text-align: left;
    width: 35%;
  }

  .itemDetailYouMayAlsoLikeContainer {
    padding: 7vh 5vw 7vh 5vw;
  }

  .itemDetailYouMayAlsoLikeContent {
    display: flex;
    gap: 5em;
  }

  .itemDetailDescriptionBrand {
    font-style: normal;
    font-weight: 500;
    font-size: 1.075vw;
    color: #006787;
    cursor: pointer;
  }

  .itemDetailDescriptionItemName {
    margin-top: 2.75vh;
    font-style: normal;
    font-weight: 500;
    font-size: 1.425vw;
  }

  .itemDetailDescriptionItemDesc {
    margin-top: 1vh;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95vw;
  }

  .variantSelectedContainer {
    color: #006787;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8vw;
  }

  .itemDetailDescriptionPrice {
    margin-top: 2.75vh;
    font-style: normal;
    font-size: 1.15vw;
    margin-bottom: 0.5em;
  }

  .itemDetailDescriptionSizeColorQtyContainer {
    margin-top: 2.75vh;
  }

  .itemDetailDescriptionOptions {
    display: flex;
    align-items: center;
  }

  .itemDetailDescriptionOptionLabel {
    font-style: normal;
    font-weight: 700;
    font-size: 1vw;
    color: #006787;
  }

  .itemDetailDescriptionOptionViewSizeGuide {
    margin-left: 2.75vh;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95vw;
    line-height: 21px;
    text-decoration-line: underline;
    color: #9e9e9e;
    cursor: pointer;
  }

  .itemDetailDescriptionSizeOptions {
    margin-top: 2.75vh;
    display: flex;
    flex-wrap: wrap;
    gap: 1.4vw;
  }

  .eachSizeOptionContainer {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .eachSizeOptionContainer div {
    margin: 0.5em 0.75em 0.5em 0.75em;
  }

  .eachSizeOptionContainer div {
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
  }

  .eachColorOptionContainer {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .eachColorOptionContainer div {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8vw;
    margin-top: 0.3em;
  }

  .eachColorOptionContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .itemDetailDescriptionQtyOptionsContainer {
    border: 1px solid #000000;
    border-radius: 5px;
  }

  .itemDetailDescriptionQtyOptions {
    margin: 0.2vh 1.25vw 0.2vh 1.25vw;
    display: flex;
    gap: 0.5vw;
    align-items: center;
  }

  .itemDetailDescriptionQtyValue {
    font-style: normal;
    font-weight: 400;
    font-size: 0.95vw;
  }

  .itemDetailDescriptionQtyBtn {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
  }

  .itemDetailDescriptionQtyInStockContainer {
    margin-left: 1vw;
    display: flex;
    gap: 0.9vw;
    align-items: center;
  }

  .itemDetailDescriptionQtyInStockBox {
    width: 1vw;
    height: 2vh;
    border-radius: 5px;
  }

  .itemDetailDescriptionQtyInStockLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
  }

  .itemDetailDescriptionBtnAddToBag {
    margin-top: 7vh;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .itemDetailDescriptionBtnAddToWishlistSkeleton {
    margin-top: 1.5vh;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .itemDetailDescriptionBtnAddToBag div {
    margin-top: 1.4vh;
    margin-bottom: 1.4vh;
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    color: #ffffff;
  }

  .itemDetailDescriptionBtnAddToWishList {
    margin-top: 1.5vh;
    background-color: #e8e8e8;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .itemDetailDescriptionBtnAddToWishList img {
    width: 1.5vw;
    height: auto;
    margin-right: 0.5vw;
  }

  .itemDetailDescriptionBtnAddToWishList div {
    margin-top: 0.7vh;
    margin-bottom: 0.7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    color: #000000;
  }

  .itemDetailDescriptionDetails {
    margin-top: 6vh;
  }

  .eachDetailDescription {
    display: flex;
    align-items: baseline;
    cursor: pointer;
  }

  .eachDetailDescriptionArrowIcon img {
    width: 1.2vw;
    height: auto;
  }

  .eachDetailDescriptionLabel {
    margin-left: 1.025vw;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2vw;
  }

  .eachDetailContainer {
    display: flex;
    align-items: center;
    gap: 1.65vw;
  }

  .eachDetailListItem {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    margin-top: -0.5em;
    margin-left: 0.25em;
  }

  .eachDetailLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    height: 15vh;
    overflow-y: auto;
  }

  .eachDetailDescriptionOptions {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  .itemDetailDescriptionProductCode {
    margin-top: 3vh;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8vw;
    color: #9e9e9e;
  }

  .itemDetailBottomSectionContainer {
    margin-top: 1vh;
    background-color: #19b0df;
  }

  .itemDetailBottomSectionContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5vh 5vw 5vh 5vw;
  }

  .offerContainer {
    text-align: left;
    width: 45vw;
  }

  .offerLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 1.785vw;
  }

  .offerDescription {
    margin-top: 3vh;
    width: 45%;
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    color: #ffffff;
  }

  .submitEmailContainer {
    width: 45vw;
    margin-top: 0em;
    text-align: left;
  }

  .submitEmailLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    color: #ffffff;
  }

  .submitEmailInputAndSubmitBtnContainer {
    margin-top: 1.85vh;
    display: flex;
    gap: 2vw;
  }

  .btnSubmitEmailUser {
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnSubmitEmailUser div {
    margin: 1vh 2vw 1vh 2vw;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    color: #ffffff;
  }
}
