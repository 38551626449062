/* mobile style */
.fullscreenContainer {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: start;
}

.fullscreenNavbarSearchInput {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}

.contentContainer {
  width: 90%;
  height: 90vh;
  background-color: #ffffff;
  overflow: auto;
}

.landingPageContentContainer {
  width: 70vw;
  height: 50vh;
  background-color: #ffffff;
  overflow: auto;
  display: flex;
}

.contentFullScreen {
  margin: 1em 1em 1em 1em;
}

.closeBtn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.beFirstToKnowContainer .closeBtn {
  margin-right: 2.5vw;
  margin-top: 1.5vh;
}

.beFirstToKnowContainer .closeBtn img {
  width: 3vw;
  height: auto;
}

.fullScreenTitle {
  margin-top: 0.5em;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  color: #006787;
}

.fullScreenMeasurementContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
}

.measurementLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.measurementSwitchContainer {
  display: flex;
  gap: 0.75em;
  justify-content: center;
  align-items: center;
}

.measurementSwitchLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #19b0df;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #006787;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #19b0df;
}

input:focus + .slider {
  box-shadow: 0 0 1px #19b0df;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14.2px);
  -ms-transform: translateX(14.2px);
  transform: translateX(14.2px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tableMeasurementDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.dividerContent {
  border: 0.98px solid #d9d9d9;
}

.sizeConversionsContainer {
  margin-top: 2em;
}

.contactUsContainer {
  margin-top: 2em;
}

.tableSizeContainer {
  margin-top: 2em;
  display: flex;
  gap: 1em;
  align-items: center;
}

.tableSizeThead {
  width: 15%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.tableSizeThead div {
  padding-top: 1em;
  padding-bottom: 1em;
}

.tableSizeTr {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trCenterContainer {
  width: 100%;
  text-align: center;
}

.tdSizeTitleContainer {
  width: 100%;
  background-color: #e8e8e8;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  border-top: 3px solid #000000;
}

.tdSizeValue {
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid #000000;
}

.firstPopUpBannerImg {
  width: 50%;
  height: auto;
  object-fit: cover;
}

.beFirstToKnowContainer {
  width: 50%;
}

.beFirstToKnowMainContainer {
  /* margin-top: 3vh; */
  /* margin-bottom: 6vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoSectionFullScreen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
}

.contentContainerLandingPagePopUpBanner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.swingClubLogoFullScreen {
  margin-top: -1vh;
  width: 25vw;
  height: auto;
}

.swingClubLabelFullScreen {
  margin-left: 0.3em;
  width: 20vw;
  height: auto;
}

.termsAndConditionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}

.termsAndConditionContainer div {
  color: #525252;
  font-size: 2vw;
  text-decoration-line: underline;
  cursor: pointer;
}

.beFirstWordStyle {
  text-align: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 2vh;
  font-size: 3vw;
  font-weight: 500;
}

.golfWithStyleWordStyle {
  padding: 1vh 1.5vw 1vh 1.5vw;
  text-align: center;
  font-size: 1.8vw;
  font-weight: 500;
}

.notifyMeBtn {
  margin-top: 1vh;
  width: 27.5vw;
  height: 3.25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  cursor: pointer;
}

.popUpBannerInputContainer {
  margin-top: 2.5vh;
  margin-bottom: 5vh;
}

.notifyMeBtn div {
  font-size: 2vw;
  color: #ffffff;
}

.accountMenuContainer {
  width: 100vw;
  height: 100vh;
}

.accountIconContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 7em;
  margin-right: 5%;
}

.accountMenuBoxContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  border-radius: 20px;
  width: 100%;
}

.accountMenuBoxContentContainer {
  padding-top: 1em;
  padding-bottom: 1em;
  width: 42vw;
  border-radius: 10px;
  margin-right: 20vw;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.eachAccountBoxMenuOptions {
  text-align: left;
  cursor: pointer;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.eachAccountBoxMenuOptions div {
  text-align: left;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.eachAccountBoxMenuOptionsEmail {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 8px;
  color: #6f6f6f;
  font-weight: 500;
}

.loadingAnimationMainContainer {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.searchOrderContainer > input:focus {
  outline: none;
}

/* desktop style */
@media only screen and (min-width: 640px) {
  .fullscreenContainer {
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fullscreenNavbarSearchInput {
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .contentContainer {
    width: 90%;
    height: 90vh;
    background-color: #ffffff;
    overflow: auto;
  }

  .landingPageContentContainer {
    width: 40vw;
    height: 70vh;
    background-color: #ffffff;
    overflow: auto;
    display: flex;
  }

  .loadingAnimationContainer {
    width: 40vw;
    height: 70vh;
    overflow: auto;
    display: flex;
  }

  .contentFullScreen {
    margin: 2.5em 3em 2.5em 3em;
  }

  .closeBtn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .beFirstToKnowContainer .closeBtn {
    margin-top: 2vh;
    margin-right: 1.5vw;
  }

  .beFirstToKnowContainer .closeBtn img {
    width: 1.5vw;
    height: auto;
  }

  .fullScreenTitle {
    margin-top: 0.5em;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #006787;
  }

  .fullScreenMeasurementContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3em;
  }

  .measurementLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .measurementSwitchContainer {
    display: flex;
    gap: 0.75em;
    justify-content: center;
    align-items: center;
  }

  .measurementSwitchLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #19b0df;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: #006787;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #19b0df;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #19b0df;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14.2px);
    -ms-transform: translateX(14.2px);
    transform: translateX(14.2px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .tableMeasurementDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }

  .dividerContent {
    border: 0.98px solid #d9d9d9;
  }

  .sizeConversionsContainer {
    margin-top: 2em;
  }

  .contactUsContainer {
    margin-top: 2em;
  }

  .tableSizeContainer {
    margin-top: 2em;
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .tableSizeThead {
    width: 15%;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }

  .tableSizeThead div {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .tableSizeTr {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trCenterContainer {
    width: 100%;
    text-align: center;
  }

  .tdSizeTitleContainer {
    width: 100%;
    background-color: #e8e8e8;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    border-top: 3px solid #000000;
  }

  .tdSizeValue {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    border-bottom: 1px solid #000000;
  }

  .firstPopUpBannerImg {
    width: 50%;
    height: auto;
    object-fit: cover;
  }

  .beFirstToKnowContainer {
    width: 50%;
  }

  .beFirstToKnowMainContainer {
    margin-top: 4vh;
    margin-bottom: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logoSectionFullScreen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .contentContainerLandingPagePopUpBanner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .swingClubLabelFullScreen {
    margin-left: 0.8vw;
    width: 11vw;
    height: auto;
  }

  .swingClubLogoFullScreen {
    margin-top: -2vh;
    width: 15vw;
    height: auto;
  }

  .termsAndConditionContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
  }

  .termsAndConditionContainer div {
    color: #525252;
    font-size: 0.9vw;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .beFirstWordStyle {
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 5vh;
    font-size: 1.6vw;
    font-weight: 500;
  }

  .golfWithStyleWordStyle {
    padding: 1vh 1.5vw 7.5vh 1.5vw;
    text-align: center;
    font-size: 1.1vw;
    font-weight: 500;
  }

  .popUpBannerInputContainer {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .notifyMeBtn {
    margin-top: 1.75vh;
    width: 77%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    cursor: pointer;
  }

  .notifyMeBtn div {
    font-size: 1vw;
    color: #ffffff;
  }

  .accountMenuContainer {
    width: 100vw;
    height: 100vh;
  }

  .accountIconContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 7em;
    margin-right: 5%;
  }

  .accountMenuBoxContainer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    border-radius: 20px;
    width: 100%;
  }

  .accountMenuBoxContentContainer {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 13vw;
    border-radius: 10px;
    margin-right: 5vw;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .eachAccountBoxMenuOptions {
    text-align: left;
    cursor: pointer;
    color: #000;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
  }

  .eachAccountBoxMenuOptions:hover {
    text-align: left;
    cursor: pointer;
    background: #e8e8e8;
    color: #000;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
  }

  .eachAccountBoxMenuOptions div {
    margin-left: 1.5vw;
    text-align: left;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0;
  }

  .eachAccountBoxMenuOptionsEmail {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6vw;
    color: #6f6f6f;
    font-weight: 500;
  }

  .searchOrderContainer > input:focus {
    outline: none;
  }
}
