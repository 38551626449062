@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-w-main {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.main-container {
  margin-top: 80px;
  text-align: left;
  min-height: min-content;
}

.list-disc {
  list-style-type: disc;
}

.list-lower-alpha {
  list-style-type: lower-alpha;
}

.list-number {
  list-style-type: decimal;
}

@media only screen and (min-width: 640px) {
  .max-w-main {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .main-container {
    margin-top: 120px;
  }
}