.myProfileMainContainer {
  margin-top: 66px;
  text-align: left;
}

.myProfileContainer {
  margin: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.myProfileSideMenuContainer {
  position: sticky;
  top: 66px;
  display: flex;
  overflow-x: auto;
  background-color: white;
  margin-bottom: 1rem;
}

.eachSideMenuTabContainer {
  padding: 1rem 0.75rem 0.5rem 0.75rem;
}

.eachSideMenuTabContainer div {
  cursor: pointer;
  color: #000;
  font-size: 12px;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tabMainContainer {
  margin-bottom: 1rem;
  width: auto;
}

.tabNameContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.tabNameStyle {
  color: #006787;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.descForgotPasswordStyle {
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}

.verifiedUserContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: red;
}

.verifiedUserContainer div {
  margin: 0.5em 1em 0.5em 1em;
  color: #000;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.changePasswordStyle {
  margin-left: 1em;
  color: #ffac60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}

.editMyProfileContainer {
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.cancelAndSaveBtnContainer {
  margin-top: 1em;
  display: flex;
}

.cancelAndSaveBtnContentContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btnCancelEditProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background: #9e9e9e;
}

.btnCancelEditProfile div {
  margin: 0.75em 1.5em 0.75em 1.5em;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.btnSaveEditProfile {
  margin-left: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background: #000;
}

.btnSaveEditProfile div {
  margin: 0.75em 2.15em 0.75em 2.15em;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.emptyWishlistContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
}

.emptyWishlistContainer img {
  width: 108px;
  height: auto;
}

.emptyWishlistContainer div {
  text-align: center;
  margin-top: 1em;
  width: 100%;
  color: #525252;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.wishlistContainer {
  padding-top: 1em;
  padding-bottom: 1em;
  display: grid;
  grid-template-columns: repeat(2, 10fr);
  gap: 0.75rem;
}

.eachWishlistContainer {
  margin: 2vh 0px 2vh 0px;
}

.eachWishlistContainer {
  margin-bottom: 1em;
}

.eachWishlistContainer img {
  width: 100%;
  height: 30vh;
  border-radius: 17.626px;
  border: 0.881px solid #e8e8e8;
  background: #fff;
  object-fit: contain;
}

.eachWishlistProductNameAndPrice {
  margin-top: 0.75em;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eachShopNowBtnAndDeleteIconContainer {
  margin-top: 0.75em;
  display: flex;
  align-items: center;
  gap: 1em;
}

.eachShopNowBtnContainer {
  width: 102px;
  height: 29.651px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 12.089px;
  font-style: normal;
  font-weight: 400;
}

.eachShopNowBtnAndDeleteIconContainer img {
  width: 22px;
  height: auto;
}

.searchOrderContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.myOrderSearchIcon {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.allMyOrdersContainer {
  margin-top: 1em;
  margin-bottom: 1em;
}

.eachMyOrderContainer {
  border-radius: 10px;
  background: #ffffff;
  margin-top: 1em;
}

.eachMyOrderContainer:hover {
  border-radius: 10px;
  background: #f9f9f9;
  margin-top: 2em;
  cursor: pointer;
  border: 1px solid #9e9e9e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.eachMyOrderContentContainer {
  padding: 1em 1em 1em 1em;
}

.eachMyOrderIdAndStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.eachMyOrderIdAndStatusMobile {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  align-items: start;
}

.eachMyOrderIdAndDateContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.eachResultSearchStyle {
  font-size: 14px;
}

.eachMyOrderId {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.eachMyOrderDate {
  margin-left: -11%;
  color: #000;
  font-size: 14px;
  font-style: normal;
}

.eachMyOrderStatusContainer {
  border-radius: 5px;
  background: #e8e8e8;
  text-align: center;
}

.eachMyOrderStatusContainer div {
  padding: 0.5vh 1vw 0.5vh 1vw;
}

.eachMyOrderImgAndTotalAmountContainer {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.eachMyOrderImgAndDescContainer {
  display: flex;
}

.eachMyOrderImgAndDescContainer img {
  border-radius: 5.677px;
  border: 0.284px solid #e8e8e8;
  width: 72.5px;
  height: auto;
}

.eachMyOrderImgAndDescContainerMobile {
  display: flex;
}

.eachMyOrderImgAndDescContainerMobile img {
  border-radius: 5.677px;
  border: 0.284px solid #e8e8e8;
  width: 27.5vw;
  height: 12.5vh;
  object-fit: cover;
}

.eachMyOrderDescContainer {
  margin-left: 1em;
}

.eachMyOrderCoverItemName {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.75em;
}

.eachMyOrderLeftItemCounter {
  color: #525252;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.eachMyOrderTotalAmountContainer {
  margin-top: 1em;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  font-size: 14px;
  font-style: normal;
}

.eachMyOrderTotalAmountLabel {
  font-weight: 700;
  margin-bottom: 0.75em;
}

.backToPreviousPageContainer {
  display: flex;
  align-items: center;
  gap: 1em;
  cursor: pointer;
}

.backToPreviousPageContainer img {
  width: 18px;
  height: auto;
}

.myOrderDetailMainContainer {
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.myOrderDetailContentContainer {
  padding: 0.75em 0.5em 0.5em 0.5em;
}

.printInvoiceBtnContainer {
  border-radius: 10px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.printInvoiceContentContainer {
  margin: 0.25em 0.5em 0.25em 0.5em;
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.printInvoiceContentContainer img {
  width: 12.533px;
  height: auto;
}

.printInvoiceContentContainer div {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.myOrderDetailBodyContainer {
  margin-top: 1em;
  margin-bottom: 0.25em;
}

.myOrderStatusSendToAndDropdownInputContainer {
  display: flex;
  gap: 1em;
  align-items: center;
}

.allItemsContainer {
  margin-top: 1em;
  margin-bottom: 1em;
}

.eachOrderItemDetailContainer {
  margin-left: 0em;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.dividerMyOrderDetail {
  border: 1px solid #d9d9d9;
}

.myOrderDetailFooterContainer {
  margin-top: 0.75em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.myOrderDetailTotalItemStyle {
  color: #525252;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.myOrderDetailDeliveryFeeAndTotalAmountMainContainer {
  width: 100%;
}

.myOrderDetailDeliveryFeeAndTotalAmountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.15em;
  color: #000;
  font-size: 13px;
  font-style: normal;
}

.btnAddNewAddress {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  background: #000;
  cursor: pointer;
}

.btnAddNewAddress div {
  margin: 0.25em 1em 0.25em 1em;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.btnEditAddress {
  cursor: pointer;
  border-radius: 5px;
  background: #000;
  margin-right: 10px;
}

.btnEditAddress div {
  margin: 0.25em 1em 0.25em 1em;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.allAddressesContainer {
  margin-top: -1em;
  margin-bottom: 1em;
}

.eachAddressContainer {
  margin-top: 2em;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.eachAddressNameStyle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.eachStreetNameAndCountryDescStyle {
  margin-top: 0.1em;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.searchResultContainer {
  margin-left: -1%;
  margin-top: 0.5em;
  position: absolute;
  z-index: 2;
  width: 79%;
  border-radius: 10px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.searchResultContentContainer {
  margin: 0.5em 1em 0.5em 1em;
}

.searchResultLabel {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.searchResultMainContainer {
  margin-top: 0.5em;
}

.promoCodeTabsMainContainer {
  display: flex;
  justify-content: space-between;
}

.eachPromoCodeTabBoxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
}

.eachPromoCodeTabBoxContainer div {
  margin: 0.25em 1.5em 0.25em 1.5em;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.eachCouponMainContainer {
  width: 100%;
  margin-top: 1em;
  border-radius: 5px;
  border: 1px solid #000;
  background: #f9f9f9;
}

.eachCouponContentContainer {
  margin: 1em 1em 1em 1em;
  display: flex;
  gap: 1em;
}

.eachCouponContentContainer img {
  width: 30px;
  height: auto;
}

.eachCouponDescContainer {
  margin-top: -0.25em;
  display: flex;
  flex-direction: column;
  gap: 0.15em;
}

.eachCouponDesc {
  font-size: 13px;
  font-style: normal;
}

.myOrderUserDetailContainer {
  text-align: left;
  margin-left: 1%;
  margin-top: 0.75em;
}

.accordionWrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.accordionListContainer {
  width: 100%;
}

.eachAccordionContainer {
  background: #f5f5f5;
  margin-bottom: 2%;
  padding: 10px 20px;
}

.eachAccordionHeader {
  color: #006787;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}

.eachAccordionContent {
  margin-top: 1em;
}

.editDeleteContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editDeleteContainer img {
  width: 17px;
  height: auto;
  cursor: pointer;
}

.myOrderSendToValueContainer {
  background: #fffefe;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid;
  border-color: #000;
}

.myOrderSendToValueContainer div {
  font-size: 4.5vw;
  padding: 1vh 2vw 1vh 2vw;
}

.myOrderSendToValueAddressDetailContainer {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 640px) {
  .myProfileMainContainer {
    margin-top: 80px;
    padding-top: 120px;
    text-align: left;
  }

  .myProfileContainer {
    margin: 5vh 5vw 3vh 5vw;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .myProfileSideMenuContainer {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    width: 22.5%;
    background-color: #f5f5f5;
    border-radius: 10px;
    position: sticky;
    height: 100%;
    top: 25.1vh;
    display: block;
    overflow-x: auto;
    margin-bottom: 1em;
  }

  .eachSideMenuTabContainer {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 2em;
  }

  .eachSideMenuTabContainer div {
    cursor: pointer;
    color: #000;
    font-size: 22px;
    font-style: normal;
  }

  .tabMainContainer {
    padding-left: 4vw;
    width: 75%;
    position: relative;
    margin-bottom: -0.9em;
  }

  .tabNameContainer {
    display: flex;
    justify-content: space-between;
  }

  .tabNameStyle {
    color: #006787;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
  }

  .descForgotPasswordStyle {
    font-size: 1vw;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }

  .verifiedUserContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .verifiedUserContainer div {
    margin: 1em 1em 1em 1em;
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .changePasswordStyle {
    margin-left: 1em;
    color: #ffac60;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .editMyProfileContainer {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  .cancelAndSaveBtnContainer {
    margin-top: 2em;
    display: flex;
    justify-content: flex-end;
  }

  .cancelAndSaveBtnContentContainer {
    display: flex;
    justify-content: flex-end;
  }

  .btnCancelEditProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background: #9e9e9e;
  }

  .btnCancelEditProfile div {
    margin: 0.75em 1.5em 0.75em 1.5em;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .btnSaveEditProfile {
    margin-left: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background: #000;
  }

  .btnSaveEditProfile div {
    margin: 0.75em 2.15em 0.75em 2.15em;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .emptyWishlistContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4em;
    margin-bottom: 4em;
  }

  .emptyWishlistContainer img {
    width: 148px;
    height: 148px;
  }

  .emptyWishlistContainer div {
    text-align: center;
    margin-top: 1em;
    width: 248px;
    color: #525252;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .wishlistContainer {
    padding-top: 3em;
    padding-bottom: 3em;
    display: grid;
    grid-template-columns: repeat(3, 10fr);
    grid-gap: 6em;
  }

  .eachWishlistContainer img {
    width: 100%;
    border-radius: 17.626px;
    border: 0.881px solid #e8e8e8;
    background: #fff;
    object-fit: cover;
  }

  .eachWishlistProductNameAndPrice {
    margin-top: 0.75em;
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .eachShopNowBtnAndDeleteIconContainer {
    margin-top: 0.75em;
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .eachShopNowBtnContainer {
    width: 102px;
    height: 29.651px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 12.089px;
    font-style: normal;
    font-weight: 400;
  }

  .eachShopNowBtnAndDeleteIconContainer img {
    width: 22px;
    height: auto;
  }

  .searchOrderContainer {
    display: flex;
    align-items: center;
    margin-top: 0;
  }

  .myOrderSearchIcon {
    width: 35px;
    height: auto;
  }

  .allMyOrdersContainer {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .eachMyOrderContainer {
    border-radius: 10px;
    background: #f9f9f9;
    margin-top: 2em;
  }

  .eachMyOrderContainer:hover {
    border-radius: 10px;
    background: #f9f9f9;
    margin-top: 2em;
    cursor: pointer;
    border: 1px solid #9e9e9e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  .eachMyOrderContentContainer {
    padding: 1.5em 2em 1.5em 2em;
  }

  .eachMyOrderIdAndStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .eachMyOrderIdAndDateContainer {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .eachMyOrderId {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .eachMyOrderDate {
    margin-left: 1em;
    color: #000;
    font-size: 16px;
    font-style: normal;
  }

  .eachMyOrderStatusContainer {
    border-radius: 5px;
    background: #e8e8e8;
  }

  .eachMyOrderImgAndTotalAmountContainer {
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .eachMyOrderImgAndDescContainer {
    display: flex;
  }

  .eachMyOrderImgAndDescContainer img {
    border-radius: 5.677px;
    border: 0.284px solid #e8e8e8;
    width: 7vw;
    height: 12.5vh;
    object-fit: cover;
  }

  .eachMyOrderDescContainer {
    margin-left: 1em;
  }

  .eachMyOrderCoverItemName {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.75em;
  }

  .eachMyOrderLeftItemCounter {
    color: #525252;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .eachMyOrderTotalAmountContainer {
    margin-top: 0;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #000;
    font-size: 14px;
    font-style: normal;
  }

  .eachMyOrderTotalAmountLabel {
    font-weight: 700;
    margin-bottom: 0.75em;
  }

  .backToPreviousPageContainer {
    display: flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;
  }

  .backToPreviousPageContainer img {
    width: 20px;
    height: auto;
  }

  .myOrderDetailMainContainer {
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 10px;
    border: 1px solid #9e9e9e;
    background: #f9f9f9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  .myOrderDetailContentContainer {
    padding: 2em 3em 2em 3em;
  }

  .printInvoiceBtnContainer {
    border-radius: 10px;
    border: 1px solid #000;
    background: #fff;
  }

  .printInvoiceContentContainer {
    margin: 0.5em 1em 0.5em 1em;
    display: flex;
    gap: 0.5em;
    align-items: center;
  }

  .printInvoiceContentContainer img {
    width: 12.533px;
    height: auto;
  }

  .printInvoiceContentContainer div {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .myOrderDetailBodyContainer {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .myOrderStatusSendToAndDropdownInputContainer {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .allItemsContainer {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .eachOrderItemDetailContainer {
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 2em;
  }

  .dividerMyOrderDetail {
    border: 1px solid #d9d9d9;
  }

  .myOrderDetailFooterContainer {
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .myOrderDetailTotalItemStyle {
    flex: 1 1 0%;
    color: #525252;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
  }

  .myOrderDetailDeliveryFeeAndTotalAmountMainContainer {
    width: unset;
  }

  .myOrderDetailDeliveryFeeAndTotalAmountContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
    color: #000;
    font-size: 14px;
    font-style: normal;
    gap: 32px;
  }

  .btnAddNewAddress {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    background: #000;
    cursor: pointer;
  }

  .btnAddNewAddress div {
    margin: 0.25em 1em 0.25em 1em;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .allAddressesContainer {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .eachAddressContainer {
    margin-top: 2em;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
  }

  .eachAddressNameStyle {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .eachStreetNameAndCountryDescStyle {
    margin-top: 0.1em;
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .searchResultContainer {
    margin-left: 0;
    margin-top: 1em;
    position: absolute;
    z-index: 2;
    width: 94.5%;
    border-radius: 10px;
    border: 1px solid #000;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  .searchResultContentContainer {
    margin: 1em 2em 1em 2em;
  }

  .searchResultLabel {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  .searchResultMainContainer {
    margin-top: 1em;
  }

  .eachResultSearchStyle {
    font-size: 16px;
  }

  .promoCodeTabsMainContainer {
    display: flex;
    justify-content: flex-start;
  }

  .eachPromoCodeTabBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .eachPromoCodeTabBoxContainer div {
    margin: 0.25em 2em 0.25em 2em;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  .eachCouponMainContainer {
    width: 50%;
    margin-top: 2em;
    border-radius: 5px;
    border: 1px solid #000;
    background: #f9f9f9;
  }

  .eachCouponContentContainer {
    margin: 1.5em 2em 1.5em 2em;
    display: flex;
    gap: 1.5em;
  }

  .eachCouponContentContainer img {
    width: 40px;
    height: auto;
  }

  .eachCouponDescContainer {
    margin-top: -0.25em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .eachCouponDesc {
    font-size: 16px;
    font-style: normal;
  }

  .myOrderUserDetailContainer {
    text-align: left;
    margin-left: 0;
    margin-top: 0;
  }

  .myOrderSendToValueContainer {
    background: #fffefe;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid;
    border-color: #000;
  }

  .myOrderSendToValueContainer div {
    font-size: 0.95vw;
    padding: 1vh 1vw 1vh 1vw;
  }

  .myOrderSendToValueAddressDetailContainer {
    display: flex;
    justify-content: flex-end;
  }

  .changePassword {
    text-align: right;
  }

  .changePassword p {
    color: #006787;
    font-size: 1em;
    cursor: pointer;
    margin-top: 0.8em;
  }
}
