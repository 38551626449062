.loginBoxMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  height: 90vh;
}

.registerBoxMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 1.25em;
  padding-bottom: 1em;
  width: 100%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  height: 90vh;
}

.registerBoxMainContainer .closeBtn img {
  width: 12px;
  height: 12px;
}

.forgotPasswordBoxMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 1.25em;
  padding-bottom: 1em;
  width: 100%;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  height: 45vh;
}

.signInToYourAccountStyle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1em;
}

.inputLabelWithIcon {
  display: flex;
  align-items: center;
  gap: 0.65em;
}

.inputLabelWithIconPassword {
  display: flex;
  align-items: center;
  gap: 0.65em;
}

.inputLabelWithIcon img {
  margin-top: -0.5em;
  width: 17px;
  height: 12px;
  object-fit: cover;
}

.inputLabelWithIconPassword img {
  margin-top: -0.5em;
  width: 11px;
  height: 14px;
  object-fit: cover;
}

.userNotFoundContainer {
  margin-top: 0.5em;
  width: 85%;
}

.userNotFoundText {
  background-color: rgb(254 226 226);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(241, 41, 41)
}

.loginInputContainer {
  margin-top: 0.5em;
  width: 85%;
}

.loginForgotPassword {
  width: 85%;
  text-align: right;
}

.loginForgotPassword p {
  color: #006787;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.loginCaptchaContainer {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 15%;
  width: 100%;
  margin-bottom: 0.5em;
}

.btnSignInContainer {
  display: flex;
  align-items: center;
  width: 85%;
  justify-content: center;
  border-radius: 25px;
  color: white;
  height: 40px;
  cursor: pointer;
  margin-top: 0.5em;
}

.inputPasswordContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.loginEyePassword {
  position: absolute;
  float: right;
  cursor: pointer;
  top: 2vh;
  right: 4%;
}

.loginEyePassword img {
  width: 20px;
  height: auto;
  margin-top: -0.4vh;
}

.authenticationPageNavigation p {
  text-align: center;
  font-size: 0.7em;
  color: #006787;
}

.authenticationPageNavigation span {
  cursor: pointer;
  font-weight: 700;
}

.inputLabelUsernameWithIcon {
  display: flex;
  align-items: center;
  gap: 0.65em;
}

.inputLabelUsernameWithIcon img {
  margin-top: -0.5em;
  width: 15px;
  height: 15px;
  object-fit: cover;
}

@media only screen and (min-width: 640px) {
  .loginBoxMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 3em;
    padding-bottom: 3em;
    width: 35vw;
    border-radius: 25px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
    height: min-content;
  }

  .registerBoxMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 2em;
    padding-bottom: 3em;
    width: 35vw;
    border-radius: 25px;
    max-height: 97.5vh;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
    height: min-content;
  }

  .registerBoxMainContainer .closeBtn img {
    width: 1.5vw;
    height: auto;
  }

  .forgotPasswordBoxMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 2vh;
    padding-bottom: 2vh;
    width: 35vw;
    border-radius: 25px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
    height: 45vh;
  }

  .changePasswordBoxMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 2vh;
    padding-bottom: 2vh;
    width: 35vw;
    border-radius: 25px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
    height: min-content;
  }

  .signInToYourAccountStyle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2em;
  }

  .inputLabelWithIcon {
    display: flex;
    align-items: center;
    gap: 0.65em;
  }

  .inputLabelWithIconPassword {
    display: flex;
    align-items: center;
    gap: 0.65em;
  }

  .inputLabelWithIcon img {
    margin-top: -0.5em;
    width: 17px;
    height: 12px;
    object-fit: cover;
  }

  .inputLabelWithIconPassword img {
    margin-top: -0.5em;
    width: 11px;
    height: 14px;
    object-fit: cover;
  }

  .userNotFoundContainer {
    margin-top: 1em;
    width: 67.5%;
    margin-left: -0.5vw;
  }

  .userNotFoundText {
    background-color: rgb(254 226 226);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(241, 41, 41)
  }

  .loginInputContainer {
    margin-top: 1em;
    width: 67.5%;
    margin-left: -0.5vw;
  }

  .loginForgotPassword {
    width: 67.5%;
    text-align: right;
    padding-bottom: 2vh;
  }

  .loginForgotPassword p {
    color: #006787;
    font-size: 0.8em;
    cursor: pointer;
    margin-top: 0.8em;
  }

  .loginCaptchaContainer {
    display: flex;
    align-items: flex-start;
    text-align: left;
    width: 67.5% !important;
    margin-bottom: 1em;
    margin-left: 0;
  }

  .btnSignInContainer {
    display: flex;
    align-items: center;
    width: 67.5%;
    justify-content: center;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    margin-top: 1em;
    padding: 8px;
  }

  .inputPasswordContainer {
    display: flex;
    align-items: center;
    position: relative;
  }

  .loginEyePassword {
    position: absolute;
    float: right;
    cursor: pointer;
    top: 35%;
    right: 4%;
  }

  .loginEyePassword img {
    width: 1.1vw;
    height: auto;
    margin-top: -0.4vh;
  }

  .authenticationPageNavigation p {
    padding-top: 1vh;
    margin-left: -2em;
    text-align: center;
    font-size: 0.8em;
    color: #006787;
  }

  .authenticationPageNavigation span {
    cursor: pointer;
    font-weight: 700;
  }

  .inputLabelUsernameWithIcon {
    display: flex;
    align-items: center;
    gap: 0.65em;
  }

  .inputLabelUsernameWithIcon img {
    margin-top: -0.5em;
    width: 15px;
    height: 15px;
    object-fit: cover;
  }
}
